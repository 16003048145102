//import logo from './logo.svg';
//import './App.css';

import React, { useState, useEffect } from 'react';

function App() {
  const [date, setDate] = useState(new Date().toLocaleTimeString('en-US'));

  useEffect(() => {
    const getNewDate = () => new Date().toLocaleTimeString('en-US');

    const tick = () => {
      const newDate = getNewDate()
      document.title = newDate;
      setDate(newDate);
    };

    const updateDate = setInterval(
      tick, 1000
    );

    return () => {
      clearInterval(updateDate);
    };
  }, []);




  return (

    <div style={styles.container}>

      <div style={styles.flexOne} />

      <span style={styles.flexOne}>
        {date}
      </span>

      <footer style={styles.footer}>
        Made with ❤️ by Ron Nathaniel
      </footer>

    </div>

  )
}

const styles = {
  container: {
    fontSize: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#dddddd',
    width: '100%',
    minHeight: '100vh',
  },
  flexOne: {
    flex: 1,
  },

  footer: {
    fontSize: 16,
    paddingBottom: '2vh'
  }
}

export default App;
